/**
 * Provide index for single dimensional array to
 * two dimensional with vertical folow
 */

const getVerticalFlowItemIndex = (count: number, columns: number, nextIndex: (itemIndex: number) => void): void => {
  const remainder = count % columns;
  const rows = Math.floor(count / columns) + (remainder > 0 ? 1 : 0);

  for (let row = 0; row < rows; ++row)
  for (let col = 0; col < columns; ++col) {
    if (((row * columns) + col) >= count) {
      break;
    }

    let columnDiff = 0;
    if (remainder > 0) {
      if (col > remainder) {
        columnDiff = remainder - col;
      }
    }

    const itemIndex = col * rows + row + columnDiff;
    
    nextIndex(itemIndex);
  }
}

export { getVerticalFlowItemIndex };
