import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Articles } from '../components/Articles';

const getArticles = (nodes) => {
  var articles = nodes.map(node => {
    if (!node.post) {
      return null;
    }
    const item = node.post;
    const title = item.frontmatter.title || item.fields.slug;
    const url = '/articles' + item.fields.slug;

    return {
      title,
      url
    }
  }).filter(post => post);

  articles.sort((x, y) => x.title.localeCompare(y.title));
  return articles;
}

const getProjects = (nodes) => {
  var articles = nodes.map(node => {
    if (!node.post) {
      return null;
    }
    const item = node.post;
    const title = item.frontmatter.title || item.fields.slug;
    const url = '/projects' + item.fields.slug;

    return {
      title,
      url
    }
  }).filter(post => post);

  articles.sort((x, y) => x.title.localeCompare(y.title));

  return articles;
}

const getBlazorNet = (nodes) => {
  var articles = nodes.map(node => {
    if (!node.post) {
      return null;
    }
    const item = node.post;
    const title = item.frontmatter.title || item.fields.slug;
    const url = '/blazornet' + item.fields.slug;

    return {
      title,
      url
    }
  }).filter(post => post);

  articles.sort((x, y) => x.title.localeCompare(y.title));

  return articles;
}

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const blogPosts = data.blogs.nodes;

  const allArticles = getArticles(blogPosts);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />

      <Articles
        header="Blazor.NET"
        articles={getBlazorNet(data.blazornet.nodes)}
      />
      
      <Articles
        header="Projects"
        articles={getProjects(data.projects.nodes)}
      />

      <Articles
        header={`Articles (${allArticles.length})`}
        articles={allArticles}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blogs: allFile(filter: {sourceInstanceName: {eq: "blog"}}) {
      nodes {
        post: childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    projects: allFile(filter: {sourceInstanceName: {eq: "projects"}}) {
      nodes {
        post: childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    blazornet: allFile(filter: {sourceInstanceName: {eq: "blazornet"}}) {
      nodes {
        post: childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
