/**
 * Displays all articles in a tabular format
 */
import React from 'react';
import { Link } from "gatsby";
import { getVerticalFlowItemIndex } from '../lib/verticalColumnIndex';
import { Article } from '../types';

export enum LinkSize {
  Normal,
  Small 
}

type Props = {
  header: string;
  articles: Article[];
  linkSize: LinkSize;
};

const Articles = ({ header, articles, linkSize }: Props): JSX.Element => {

  if (!articles || articles.length === 0) {
    return null;
  }

  // reorder items in column order
  const columns = 3;

  // let vertialItems: Article[] = new Array<Article>(articles.length + additional);
  const linkElements: JSX.Element[] = [];

  let linkItemCss = "article-links__item";
  if (linkSize === LinkSize.Small) {
    linkItemCss = ' article-links__item--small';
  }

  getVerticalFlowItemIndex(articles.length, columns, (index) => {
    const article = articles[index];
    let link: JSX.Element = null;
    linkElements.push((
      <div className={linkItemCss}>
        <Link to={article.url} itemProp="url">
          <span>{article.title}</span>
        </Link>
      </div>
    ));
  });

  return (
    <div className="article-links">
      <div className="article-links__header">
        <span>{header}</span>
      </div>
      {
        linkElements
      }

    </div>
  )
};

export { Articles };
